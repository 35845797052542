import { Bridge } from "@socket.tech/plugin";
import { useEffect, useState } from 'react';
import { ethers } from "ethers";

declare global {
  interface Window {
    ethereum: any;
  }
}

const SocketBridge = () => {

  const [provider, setProvider] = useState({});
  const apiKey = "645b2c8c-5825-4930-baf3-d9b997fcd88c"; //TODO: move to env after testing

  const fetchWalletData = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send('eth_requestAccounts', []); // <- this promps user to connect metamask
    setProvider(provider)
  };

  useEffect(() => {
    if (window.ethereum) {
      fetchWalletData();
    }

    window.ethereum.on("accountsChanged", function () {
      fetchWalletData();
    })
  },[]);

  // Pre-selecting default source chain as Binance and destination chain as Polygon
  const defaultSourceNetwork = 56;
  const defaultDestNetwork = 137;

  return (
      <Bridge
        provider={provider}
        API_KEY={apiKey}
        enableRefuel
				defaultSourceNetwork={defaultSourceNetwork}
				defaultDestNetwork={defaultDestNetwork}
        customize={{
          accent: 'rgb(131,249,151)',
          interactive: 'rgb(0,0,0)',
          onAccent: 'rgb(0,0,0)',
          onInteractive: 'rgb(240,240,240)',
          primary: 'rgb(25,39,51)',
          secondary: 'rgb(8,92,124)',
          secondaryText: 'rgb(200,200,200)',
          text: 'rgb(255,255,255)'
        }}
      />
  )
}

export default SocketBridge;