// import { LiFiWidget, WidgetConfig } from '@lifi/widget';
import SocketBridge from '../src/socket/index'

// const widgetConfig: WidgetConfig = {
//   integrator: 'Ten Finance',
//   containerStyle: {
//     border: '1px solid rgb(234, 234, 234)',
//     borderRadius: '16px',
//   },
//   fromChain: 56,
//   toChain: 137,
//   variant: 'expandable',
//   sdkConfig: {   //sdkConfig Not working above configs works
//     defaultRouteOptions: {
//       slippage: 0.02,
//       order: 'SAFEST',
//     }
//   },
// };

const App = () => {

  return <SocketBridge />;
  // return <LiFiWidget config={widgetConfig} />;
};

export default App;
